<template lang="">
  <div class="table">
    <NewModalSettings
      v-if="columns.length"
      pageName="productsAdvertising"
      :columns="columns"
      :showZeros="getShowZeros()"
      @setShowZeros="setShowZeros"
      @setColumns="setColumns"
      ref="settingsModal"
    />
    <ag-grid-vue
      class="ag-theme-alpine custom-grid"
      style="width: 100%; height: 100%"
      :columnDefs="columns"
      :rowData="items"
      :gridOptions="gridOptions"
      :defaultColDef="defaultColDef"
      :pinnedBottomRowData="getPinnedBottomRowData()"
      @grid-ready="onGridReady"
      @filter-changed="onFilterChanged"
    ></ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapGetters, mapActions } from 'vuex';
import TableHeader from '@/components/ui/table/TableHeader.vue';
import TableFilterModal from '@/components/ui/table/TableFilterModal.vue';
import NewModalSettings from '@/components/ui/table/NewModalSettings.vue';
import TableHeaderSettings from '@/components/ui/table/TableHeaderSettings.vue';
import ImgTooltip from '@/components/images/ImgTooltip.vue';
import TableImageComponent from '@/components/ui/table/TableImageComponent';
import LinkCell from '@/components/ui/table/LinkCell.vue';
import { numberFormatter } from '@/helpers/numberFormatter';
export default {
  name: 'Table',
  props: ['items', 'totalsItems'],
  components: {
    AgGridVue,
    agColumnHeader: TableHeader,
    TableFilterModal,
    NewModalSettings,
    TableHeaderSettings,
    ImgTooltip,
    TableImageComponent,
    LinkCell,
  },
  data() {
    return {
      columns: [],
      gridOptions: {},
      defaultColDef: {
        resizable: true,
        width: 150,
        filter: false,
        sortable: true,
        unSortIcon: true,
        cellDataType: false,
        alwaysShowHorizontalScroll: true,
        alwaysShowVerticalScroll: true,
        headerComponentParams: {
          pageName: 'productsAdvertising',
        },
      },
      gridApi: null,
    };
  },
  computed: {
    ...mapGetters('productsAdvertising', ['options']),
  },
  methods: {
    ...mapActions('productsAdvertising', ['getData', 'setOptions']),
    onFilterChanged() {
      const filterModel = this.gridApi.getFilterModel();
      localStorage.setItem(
        `productsAdvertising-filters`,
        JSON.stringify(filterModel)
      );
    },
    setShowZeros(show_zeros) {
      this.setOptions({
        show_zeros,
      });
      this.getData();
    },
    resetFilters() {
      this.gridApi.setFilterModel(null);
    },
    showSettingsModal() {
      this.$refs.settingsModal.open();
    },

    setColumns(columns) {
      this.gridApi.setGridOption('columnDefs', columns);
    },

    getShowZeros() {
      return this.options?.show_zeros ?? true;
    },

    getPinnedBottomRowData() {
      return [this.totalsItems];
    },
    getFieldName(prefix, name) {
      return prefix ? `${prefix}.${name}` : name;
    },
    getSalesReports() {
      return [
        {
          field: 'sales_report.total_ordered_in_pieces',
          name: 'total_ordered_in_pieces',
          headerName: 'Заказано, шт',
          valueFormatter: numberFormatter,
        },
        {
          field: 'sales_report.total_ordered_in_rubles',
          name: 'total_ordered_in_rubles',
          headerName: 'Заказано, шт',
          valueFormatter: numberFormatter,
        },
        {
          field: 'sales_report.total_redeemed',
          name: 'total_redeemed',
          headerName: 'Выкуплено, шт',
          valueFormatter: numberFormatter,
        },
        {
          field: 'sales_report.total_to_transfer',
          name: 'total_to_transfer',
          headerName: 'Выручка с продаж, руб',
          valueFormatter: numberFormatter,
        },
        {
          field: 'sales_report.drr',
          name: 'drr',
          headerName: 'TACoO, % от суммы заказов',
          valueFormatter: numberFormatter,
        },
      ];
    },
    getItemColumns(prefix) {
      return [
        {
          field: this.getFieldName(`${prefix}`, 'consumption'),
          headerName: 'Расход, руб',
          name: 'consumption',
          headerTooltip:
            'Расход денежных средств на автоматические рекламные кампании за выбранный период относительно артикула. (из рекламной статистики ВБ)',
          valueFormatter: numberFormatter,
          sort: prefix === 'total' ? 'desc' : false,
        },
        {
          field: this.getFieldName(`${prefix}`, 'views'),
          name: 'views',
          headerName: 'Просмотры',
          valueFormatter: numberFormatter,
        },
        {
          field: this.getFieldName(`${prefix}`, 'clicks'),
          name: 'clicks',
          headerName: 'Клики',
          valueFormatter: numberFormatter,
        },

        {
          field: this.getFieldName(`${prefix}`, 'cart'),
          name: 'cart',
          headerName: 'Корзина, шт',
          headerTooltip:
            'Количество единиц добавленых товаров в корзину артикула по всем автоматическим рекламным кампаниям за выбранный период. (из рекламной статистики ВБ)',
          valueFormatter: numberFormatter,
        },
        {
          field: this.getFieldName(`${prefix}`, 'order'),
          headerName: 'Заказ, шт',
          name: 'order',
          headerTooltip:
            'Количество единиц заказанных товаров артикула по всем автоматическим рекламным кампаниям за выбранный период. (из рекламной статистики ВБ)',
          valueFormatter: numberFormatter,
        },

        {
          field: this.getFieldName(`${prefix}`, 'cpl'),
          name: 'cpl',
          headerName: 'CPL, руб.',
          headerTooltip:
            '(«Cost per lead» - цена за лид). Стоимость одного добавления в корзину относительно артикула по автоматическим рекламным кампаниям за выбранный период. (считаем формулой "расход,руб разделить на корзины в шт)',
          valueFormatter: numberFormatter,
        },
        {
          field: this.getFieldName(`${prefix}`, 'cpo'),
          name: 'cpo',
          headerName: 'CPO, руб',
          headerTooltip:
            '(«Cost per Order» - стоимость оформленного заказа). Стоимость одного оформленного заказа по автоматическим рекламным кампаниям относительно артикула за выбранный период. (считаем формулой "расход,руб разделить на заказы в шт)',
          valueFormatter: numberFormatter,
        },
        {
          field: this.getFieldName(`${prefix}`, 'cpm'),
          name: 'cpm',
          headerName: 'CPM',
          valueFormatter: numberFormatter,
        },
        {
          field: this.getFieldName(`${prefix}`, 'cpc'),
          name: 'cpc',
          headerName: 'CPC',
          valueFormatter: numberFormatter,
        },
        {
          field: this.getFieldName(`${prefix}`, 'ctr'),
          name: 'ctr',
          headerName: 'CTR',
          valueFormatter: numberFormatter,
        },
      ];
    },
    generateTableColumns() {
      const savedSorting = localStorage.getItem('productsAdvertising-sort');
      this.columns = [
        {
          headerGroupComponent: 'TableHeaderSettings',
          headerGroupComponentParams: {
            showSettingsModal: this.showSettingsModal,
          },
          groupName: 'settings',
          children: [
            {
              field: 'image',
              cellRenderer: 'TableImageComponent',
              cellRendererParams: (params) => {
                return {
                  link: params.node.data.wb_catalog_url,
                };
              },
              headerName: 'Фото',
              pinned: 'left',
              tooltipField: 'image',
              tooltipComponent: 'ImgTooltip',
              sortable: false,
              disableCheckbox: true,
            },
            {
              field: 'nm_id',
              headerName: 'Артикул WB',
              name: 'nm_id',
              pinned: 'left',
              filter: 'TableFilterModal',
              cellRenderer: 'LinkCell',
              cellRendererParams: (params) => {
                return {
                  link: `/reports/products-statistic/${params.value}`,
                  targetBlank: true,
                };
              },
            },
            {
              field: 'sa_name',
              headerName: 'Артикул поставщика',
              name: 'sa_name',
              pinned: 'left',
              filter: 'TableFilterModal',
            },
            {
              field: 'subject',
              headerName: 'Предмет',
              name: 'subject',
              filter: 'TableFilterModal',
            },
            {
              field: 'brand',
              name: 'brand',
              headerName: 'Бренд',
              filter: 'TableFilterModal',
            },
          ],
        },
        {
          headerName: 'Автоматическая',
          groupName: 'auto_company',
          children: this.getItemColumns('auto_company'),
        },
        {
          headerName: 'Аукцион',
          groupName: 'search_and_catalog',
          children: this.getItemColumns('search_and_catalog'),
        },
        {
          headerName: 'Итого',
          groupName: 'total',
          children: [
            ...this.getItemColumns('total'),
            {
              field: 'total.total_ordered_in_rubles_drr',
              name: 'total_ordered_in_rubles_drr',
              headerName: 'Сумма заказов',
              valueFormatter: numberFormatter,
            },
            {
              field: 'total.drr',
              name: 'drr',
              headerName: 'ДРР, %',
              valueFormatter: numberFormatter,
            },
          ],
        },
        {
          headerName: 'Отчет ВБ',
          groupName: 'sales_report',
          children: [
            {
              field: 'sales_report.total_ordered_in_pieces',
              headerName: 'Заказано, шт.',
              name: 'total_ordered_in_pieces',
              headerTooltip:
                'Суммарное количество единиц заказанного товара в штуках за выбранный период (из воронки продаж ВБ. Совпадает с отчётом "Еженедельная динамика и анализ продаж")',
              valueFormatter: numberFormatter,
            },
            {
              field: 'sales_report.total_ordered_in_rubles',
              headerName: 'Заказано, руб.',
              name: 'total_ordered_in_rubles',
              headerTooltip:
                'Суммарное количество заказанного товара в рублях за выбранный период (из воронки продаж ВБ. Сервис тянет каждый заказ по сделанной в моменте цене)',
              valueFormatter: numberFormatter,
            },
            {
              field: 'sales_report.total_redeemed',
              name: 'total_redeemed',
              headerName: 'Выкуплено, шт.',
              headerTooltip:
                'Суммарное количество единиц выкупленного товара в штуках за выбранный период (ориентир - воронка продаж ВБ. Совпадает с отчётом "Еженедельная динамика и анализ продаж")',
              valueFormatter: numberFormatter,
            },

            {
              field: 'sales_report.total_to_transfer',
              name: 'total_to_transfer',
              headerName: 'Выручка с продаж, руб',
              valueFormatter: numberFormatter,
              headerTooltip:
                'Суммарное количество выкупленного товара в рублях за выбранный период (из воронки продаж ВБ. Сервис тянет каждую продажу по сделанной в моменте цене)',
              width: 200,
            },
            {
              field: 'sales_report.buyout_percent',
              name: 'buyout_percent',
              headerName: '% выкупа',
              valueFormatter: numberFormatter,
            },
            {
              field: 'sales_report.projected_revenue',
              name: 'projected_revenue',
              headerName: 'Прогнозируемая выручка, руб',
              valueFormatter: numberFormatter,
            },
            {
              field: 'sales_report.drr',
              name: 'drr',
              headerName: 'TACoO, % от суммы заказов',
              valueFormatter: numberFormatter,
              headerTooltip:
                'TACoO (Total Advertising Cost of Order - общая стоимость заказа на рекламу). Расходы на рекламу по отношению к общей сумме заказов, за выбранный период. (считаем формулой "(расходы на рекламу в руб / общий объем заказов в руб) х 100%)',
              width: 250,
            },
            {
              field: 'sales_report.tacos',
              name: 'tacos',
              headerName: 'TACoS, %',
              valueFormatter: numberFormatter,
            },
          ],
        },
      ];
      if (savedSorting) {
        const parsedSorting = JSON.parse(savedSorting);
        this.columns = this.getSortColumns(this.columns, parsedSorting);
      }
    },
    getSortColumns(columns, parsedSorting) {
      return columns.reduce((acc, column) => {
        if (column.children) {
          column.children = this.getSortColumns(column.children, parsedSorting);
          return [...acc, column];
        }
        return column.field === parsedSorting.order_field
          ? [
              ...acc,
              {
                ...column,
                sort: parsedSorting.order_type,
              },
            ]
          : [...acc, column];
      }, []);
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.generateTableColumns();
      const savedFilters = localStorage.getItem(`productsAdvertising-filters`);
      if (savedFilters) {
        setTimeout(() => {
          this.gridApi.setFilterModel(JSON.parse(savedFilters));
        }, 0);
      }
    },
  },
};
</script>
<style lang=""></style>
